// @import url('https://fonts.googleapis.com/css?family=Manrope');
@import 'src/styles/variables';
@import 'src/styles/mixins';

@font-face {
  font-family: 'Manrope';
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/satoshi/Satoshi-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: $manrope;
  vertical-align: baseline;
  box-sizing: border-box;
  text-decoration: none;
  color: #18232e;
  border: none;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: $white;
}

::-webkit-scrollbar-thumb {
  background-color: $gray;
  border-radius: 5px;
  border: 2px solid $white;
}

html,
body,
#root {
  height: 100%;
  font-family: $manrope;
  font-weight: 500;
  color: $black;
  background-color: $white;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type='date'] {
  width: 100%;
  min-width: 100%;
  min-height: 46px;
  height: 46px;
  box-sizing: border-box;
  text-align: left;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
}

input[type='password'] {
  font-family: 'Verdana';
}

input::-webkit-date-and-time-value {
  text-align: left;
}

input {
  font-family: $manrope;
}

.custom-slide {
  width: fit-content !important;
  height: auto !important;
  box-sizing: border-box;
}

.swiper-thumbs {
  .swiper-wrapper {
    display: flex;
    justify-content: center;

    @include vp-767 {
      justify-content: flex-start;
    }
  }
}

.swiper-cards {
  .swiper-slide {
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  .swiper-slide-active {
    pointer-events: auto;
  }
}

.swiper-slide-thumb-active {
  outline: 1px solid $white;
  outline-offset: -1px;
  border-radius: 16px;
}

.swiper-pagination-horizontal {
  bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.show {
  animation: show 0.3s ease-in-out;
}

.hide {
  animation: hide 0.3s ease-in-out;
}

.info {
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 72px;

  @include vp-767 {
    margin-bottom: 42px;
  }

  h1 {
    @include text(48px, 800, 58px);
    margin-bottom: 16px;

    @include vp-767 {
      @include text(36px, 800, 38px);
    }
  }

  h2 {
    @include text(24px, 800, 32px);
    text-align: left;

    @include vp-767 {
      @include text(18px, 800, 24px);
    }
  }

  b {
    @include text(16px, 700, 24px);
  }

  a {
    color: $primary;
  }

  em {
    color: $primary;
    font-style: normal;
  }

  ul {
    @include text(16px, 500, 24px);
  }

  ol {
    @include text(16px, 500, 24px);
  }

  li {
    margin-left: 28px;
  }

  img {
    width: 100%;
    max-height: 585px;
    object-fit: cover;
    border-radius: 16px;
  }

  table {
    tr {
      display: flex;

      th:first-child {
        width: 200px;
        min-width: 200px;
        border-radius: 16px 0 0 16px;

        @include vp-767 {
          width: 120px;
          min-width: 120px;
        }
      }

      th:last-child {
        border-radius: 0 16px 16px 0;
      }

      th:nth-child(2) {
        @include vp-767 {
          border-radius: 0 16px 16px 0;
        }
      }

      th:nth-child(3) {
        @include vp-767 {
          display: none;
        }
      }

      td:first-child {
        width: 200px;
        min-width: 200px;
        border: none;

        @include vp-767 {
          width: 120px;
          min-width: 120px;
        }
      }

      td:nth-child(3) {
        margin-left: 32px;

        @include vp-767 {
          display: none;
        }
      }

      td {
        padding: 32px 0 32px 32px;
        border-radius: 16px;
        border: 1px solid #979da552;
        margin-bottom: 8px;
        width: 100%;
        transition: all 0.3s;
        display: flex;
        align-items: center;

        @include vp-767 {
          padding: 12px;
        }
      }

      th {
        text-align: start;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 32px 0 32px 32px;
        background-color: $light-gray;
        width: 100%;
        @include text(16px, 700, 24px);
        color: $primary;
        margin-bottom: 24px;

        @include vp-767 {
          padding: 12px;
        }
      }
    }

    img {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    i {
      color: $primary;
      @include text(16px, 700, 24px);
    }
  }
}

.hiddenTable {
  table {
    display: none;
  }
}

.modal-opened {
  overflow: hidden;
}

.pane {
  width: 100%;
  max-width: none !important;
}
